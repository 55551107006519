export const lang = {
  dashboard: '我的首页',
  customerAdded: '已添加客户',
  authorizedShopifyCustomers: '已授权Shopify的客户',
  validInvoice: '已生成有效发票',
  pcs: '个',
  recentCooperation: '最近合作客户',
  validInvoice2: '有效发票',
  authorizedShopifyStores: '授权Shopify店铺数',
  importOrder: '再导入一批订单',
  importFirstOrder: '导入首批订单',
  more: '更多',
  addNumCustomer: '添加我的第 {num} 个客户',
  welcomeOurMallApp: '欢迎使用OurMall Dropshopping App',
  relevantInformation: '为了方便您与您的客户进行沟通，请完善您的相关信息',
	customersView: '该信息将会用于给您的客户查看',
	yourEmail: '您的联系Email',
	yourCompany: '您的公司英文名',
	modify: '更改',
	add: '添加',
	save: '确 定',
	nameEnter: '公司名称必须输入',
  待完善报价的采购单: '待完善报价的采购单',
  待发货的采购单: '待发货的采购单',
  订单异常禁止发货: '订单异常禁止发货',
  同步发货信息失败: '同步发货信息失败',
  销售统计: '销售统计',
  每日统计: '每日统计',
  日期: '日期',
  下单客户量: '下单客户量',
  订单数:'订单数',
  销售额US$: '销售额 US$',
  '显示更多': '显示更多',
  信息汇总: '信息汇总',
  客户: '客户',
  客户店铺: '客户店铺',
  托管管理订单: '托管管理订单',
  未合作商品: '未合作商品',
  可以进行报价: '可以进行报价',
  客户已订阅: '客户已订阅',
  '日期范围': '日期范围',
  '今天': '今天',
  '7天前': '7天',
  '30天前': '30天',
  '90天前': '90天',
  '一年前': '一年',
  '自定义': '自定义',
  '起始': '起始',
  '截止': '截止',
}
