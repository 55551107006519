export const lang = {
	welcome: 'Welcome, ',
	login: 'Login now to solve your troubles',
	enter: 'Enter Now',
	exit: 'Secure Exit',
	mobile: 'Email',
	pass: 'Password',
	sign: 'Sign in',
	signup: 'No account? Sign up now!',
	username: 'The username must be entered',
	passwordMust: 'Password must be entered',
	everyDay: 'Every day, we have to manually process thousands of offline orders from Shopify webmaster',
	support1: 'There is no system support for offline orders. ',
	support2: 'Every time I deal with them, I have to look at my programmer face',
	hard1: 'The format of the order file given by the stationmaster is various, and I have to match it manually every time. ',
	hard2: 'It\'s too difficult for me',
	time: 'Where\'s my time?',
	orders1: 'Thousands of orders have been processed, ',
	orders2: 'and then I need to manually export orders to Shopify webmaster..',
	getCaptcha: 'Slide to get the captcha',
	success: 'Successful',
	correctNum: 'Please enter the correct Email',
	read: 'Please read and agree to the registration agreement',
	slideBar: 'Please slide the verification bar',
	successful: 'Successful sign up, please sign in',
	captcha: 'Captcha',
	enterMobile: 'Please enter your Email',
	enterPass: 'Please input a password',
	passBits: 'The password must contain letters, numbers, and special characters, Minimum 8 characters',
	enterCaptcha: 'Please enter the captcha',
	signin: 'Already have an account? Sign in',
	complete: 'Sign up NOW',
	agreement: 'Sign up agreement',
	agree: 'I have read and agree',
	"免费注册": 'Join FREE',
	"重新获取验证码": 'try again in {second} seconds',
	"忘记密码":'Forgot Password?',
	"重置登录密码": "Reset password",
	"请输入新密码": "Please type a new password",
	"请重新输入新密码": "Please retype your new password",
	"修改登录密码": "Confirm",
	"关闭": "Discard",
	"请再次输入密码": "Please reenter your password",
	"两次输入密码不一致": "Passwords does not match!",
	"密码重置成功": "Password reset successful",
}
