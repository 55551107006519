export const lang = {
	name: '中',
	close: '关闭',
	iGotIt: '我知道了',
	successfullyToClipboard: '已经成功复制到剪切板',
	failedToClipboard: '复制到剪切板失败，请手工复制',
	main: {
		vendorCheckingOrder: '待我报价',
		pendingPayment: '等待客户付款',
		verifyingPayment: '待我确认收款',
		readyToShip: '待我发货',
		partiallyDelivered: '部分发货',
		delivered: '全部发货',
		waitingForReconfirm: '等待确认',
		cancelled: '作废',
	},
	router: {
		Quotation: '我的报价单',
		dashboard: '我的首页',
		customer: '我的客户',
		myInvoice: '我的采购订单',
		billList: '账单详情',
		ticket: '客服支持',
		import: '导入',
		invoice: '发票',
		preview: '预览发票',
		signin: '登录',
		signup: '注册',
		shopifyOrder: '一键托管店铺生成订单',
		Pricing: '系统定价',
		invite: '邀请',
		'全部订单': '全部订单',
		'待报价采购单': '待报价采购单',
		'待客户付款': '待客户付款',
		'待我确认收款': '待我确认收款',
		'已付款待发货': '已付款待发货',
		'全部发货': '全部发货',
		'有异常': '有异常',
		'账户概览': '账户概览',
	},
	app: {
		'我的报价单': '我的报价单',
		'未合作商品报价': '未合作商品报价',
		dashboard: '我的首页',
		billList: '账单详情',
		customer: '我的客户',
		entrustedShopifyStores: '已托管的客户店铺',
		myOrder: '我的采购订单',
		help: '帮助',
		service: '客服支持',
		myProfile: '编辑用户信息',
		modifyPassword: '修改密码',
		userApiToken: '用户令牌',
		exit: '退出登录',
		thisInformationWill: '此信息将在发票页面向客户进行展示',
		yourCompanyName: '您的公司英文名',
		yourCustomerWill: '客户查看的英文名称',
		companyLOGO: '企业LOGO',
		remove: '移除',
		uploadImage: '上传图片',
		address: '地址',
		email: '邮箱',
		modifyEmail: '修改邮箱',
		addEmail: '添加邮箱',
		save: '修改',
		discard: '取消',
		originalPassword: '原密码',
		newPassword: '新密码',
		repeatNewPassword: '重复新密码',
		ERPUserApiToekn: 'ERP用户令牌',
		howToUseERPToken: '如何使用ERP用户令牌',
		weHaveAlso_: '我们也已经与国内多家ERP厂商进行或正在进行合作，方便正在使用这些ERP软件的用户直接在ERP系统中快速对已确认付款的采购订单进行拉单和标记发货操作。',
		youOnlyNeed_: '您只需要复制ERP用户令牌，并且将令牌粘贴到对应的ERP软件中就可以开始对OurMall系统中已确认收款的采购订单进行操作了！',
		picture1: '图一：复制ERP用户令牌',
		picture2: '图二：将令牌粘贴到对应的ERP软件',
		atPresentChina_: '目前已经上线的国内ERP软件：',
		MabangERP: '马帮ERP',
		andSoOn_: '等，其他多家国内知名ERP厂商正在洽谈合作中，近期也将陆续上线。',
		atTheSame: '* 与此同时，为了方便广大供应商卖家对已确认收款的采购订单进行拉单和发货处理，OurMall提供了简便易用的Open API，有自研发能力的供应商可以',
		contactWithOur: 'contact with our R & D team',
		dear: '亲爱的：',
		ourAppHas_: '我们的应用已经更新了您正在访问的页面， 我们将在10秒后刷新页面',
		iSeeRefresh_: '知道了，刷新页面',
		modifyUserEmail: '修改用户邮箱',
		verify: '验证',
		_captchaYouReceived: '请输入您收到的验证码',
		captcha: '验证码',
		getCaptcha: '获取验证码',
		secondsLater: '秒后重新获取',
		_bindYourEmail: '是时候绑定您的邮箱了',
		_pleaseBindYourEmail: '您已经开始使用采购订单了，为了能及时收到新订单通知，请绑定您的邮箱',
		RemindMeLater: '以后再说',
		bindNow: '立即绑定',
		_dragToHTheRight: '请按住滑块，拖动到最右边',
		theCaptchaWas_: '发送验证码成功',
		userEmailModifiedSuccessfully: '修改用户邮箱成功',
		validationPassed: '验证通过',
		pleaseFillEmail: '请填写正确的邮箱地址',
		_wewillReplyASAP: '提交工单成功，我们将会尽快回复！',
		theOriginalEntered: '原密码必须输入',
		theNewPasswordEntered: '新密码必须输入',
		pleaseEnterAgain: '请再输入一次新密码',
		passwordChangedSuccessfully: '修改密码成功',
		companyRequired: '公司英文名必须输入',
		userInformationSuccessfully: '修改用户信息成功',
		'通知': '通知',
		'该站长可处理的单量不足，请联系站长购买单量包。': '该站长可处理的单量不足，请联系站长购买单量包。',
		'请将该提醒转发您的站长客户：': '请将该提醒转发您的站长客户：',
		'您若有疑问，': '您若有疑问，',
		'可点此联系客服': '可点此联系客服',
		'关闭': '关闭',
		"Youdon": "You don't have enough order QTY to create purchase order, please recharge your order QTY in Recharge Package page.",
		'财务': '财务',
		'钱包': '钱包',
		'设置收款方式': '设置收款方式',
		'公告管理': '公告管理',
	},
	dashboard: require('./dashboard/zh').lang,
	signin: require('./signin/zh').lang,
	signup: require('./signup/zh').lang,
  adv: require('./adv/zh').lang,
}
