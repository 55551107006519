export const lang = {
	signup: 'Sign up',
	mobile: 'Email*',
	pass: 'Password*',
	captchaSent: 'The captcha has been sent',
	captchaSentSec: 'Try again in {sec} seconds',
	captchaAgain: 'Get the captcha again',
	captcha: 'Captcha',
	agree: 'I have read and agree',
	agreement: 'Sign up Agreement',
	complete: 'Sign up NOW',
	signin: 'Already have an account? Sign in',
	enterMobile: 'Please enter your Email',
	errorMobile: 'Email format error',
	enterPass: 'Please input a password',
	passBits: 'The password must contain letters, numbers, and special characters, Minimum 8 characters',
	enterCaptcha: 'Please enter the captcha',
	getCaptcha: 'Slide to the far right to get the captcha',
	correctNum: 'Please enter the correct Email',
	slideBar: 'Please slide the verification bar',
	read: 'Please read and agree to the registration agreement',
	successful: 'Successful sign up, please sign in',
	success: 'Successful',
	用户注册协议:'Sign up agreement',
	我知道了:'I SEE'
}