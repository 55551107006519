export const lang = {
	welcome: '欢迎您，',
	login: '立即登录解决您的烦恼',
	enter: '进入系统',
	exit: '安全退出',
	mobile: 'Email/手机号',
	pass: '登录密码',
	sign: '立即登录',
	signup: '没有账号? 立即注册',
	username: '用户名必须输入',
	passwordMust: '密码必须输入',
	everyDay: '每天都要手工处理Shopify站长的几千个线下订单,',
	support1: '线下订单没有系统支持',
	support2: '每次处理都要看IT小哥哥的脸色',
	hard1: '站长给的订单文件格式五花八门,',
	hard2: '每次必须手工匹配，我太难了……',
	time: '我的时间都去哪儿了？',
	orders1: '客户3000的订单发货了! ',
	orders2: '还要手工整理出来发给客户……',
	getCaptcha: '滑动到最右边获取验证码',
	success: '验证通过',
	correctNum: '请输入正确的Email/手机号',
	read: '请先阅读并同意注册协议',
	slideBar: '请滑动验证条',
	successful: '注册成功请登录',
	captcha: '验证码',
	enterMobile: '请输入Email/手机号',
	enterPass: '请输入密码',
	passBits: '密码必须包含字母、数字和特殊字符，至少8个字符',
	enterCaptcha: '请输入验证码',
	signin: '已经有账号了? 去登录',
	complete: '完成注册',
	agreement: '注册协议',
	agree: '我已阅读并且同意',
	"免费注册": '免费注册',
	"重新获取验证码": '{second}秒后可重新获取',
	"忘记密码":'忘记密码？',
	"重置登录密码": "重置登录密码",
	"请输入新密码": "请输入新密码",
	"请重新输入新密码": "请重新输入新密码",
	"修改登录密码": "修改登录密码",
	"关闭": "关闭",
	"请再次输入密码": "请再次输入密码",
	"两次输入密码不一致": "两次输入密码不一致!",
	"密码重置成功": "密码重置成功",
}
