export const lang = {
	"发布公告": 'annouce',
	"公告标题": 'announcement title',
	"启用":'enable',
	"停用": "disable",
	"状态": "status",
	"发布对象": "Post object",
	"供应商": "supplier",
	"站长": "webmaster",
	"创建时间": "creation time",
	"操作": "operate",
	"编辑": "edit",
	"删除": "delete",
	"站长(分销商)": "Webmaster (Distributor)",
	"发布内容": "Post content",
	"取消发布": "unpublish",
	"确定发布": "publish",
	"编辑公告描述": "Edit Announcement Description",
	"图片大小不能超过": "Image size cannot exceed",
	"不能识别此文件": "this file is not recognized",
}
