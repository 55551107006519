export const lang = {
  name: 'EN',
  close: 'close',
  iGotIt: 'I got it!',
  successfullyToClipboard: 'Successfully copied to the clipboard',
  failedToClipboard: 'Failed to copy to clipboard, please copy manually',
  main: {
    vendorCheckingOrder: 'Vendor Checking Order',
    pendingPayment: 'Pending Payment',
    verifyingPayment: 'Verifying Payment',
    readyToShip: 'Ready to ship',
    partiallyDelivered: 'Partially Delivered',
    delivered: 'Delivered',
    waitingForReconfirm: 'Waiting for reconfirm',
    cancelled: 'Cancelled',
  },
  router: {
    Quotation:'My Quotations',
    dashboard: 'Dashboard',
    customer: 'Customers',
    myInvoice: 'Purhcase Orders',
    ticket: 'Tickets',
    import: 'Import',
    billList: 'Bills',
    invoice: 'Invoice',
    preview: 'Preview',
    signin: 'Sign in',
    signup: 'Sign up',
    shopifyOrder: 'Entrusted Shopify Store',
    Pricing: 'Upgrade',
    invite: 'invite',
    '全部订单': 'All Orders',
    '待我确认收款': 'Waiting for my confirmation of payment',
    '待我报价': 'Waiting for offer',
    '待客户付款': 'Waiting for payment',
    '已付款待发货': 'Waiting for fulfill',
    '全部发货': 'All fulfilled',
    '有异常': 'Abnormal',
		'公告管理': 'announcement',
  },
  app: {
    '未合作商品报价': 'Offer Price',
    '我的报价单':'My Quotations',
    dashboard: 'Dashboard',
    customer: 'Customers',
    billList: 'Bills',
    entrustedShopifyStores: 'Entrusted Stores',
    myOrder: 'Purhcase Orders',
    help: 'Help Center',
    service: 'Support',
    myProfile: 'My Profile',
    modifyPassword: 'Modify Password',
    userApiToken: 'User Api Token',
    exit: 'Exit',
    thisInformationWill: 'This information will be displayed to the customer on the invoice page',
    yourCompanyName: 'Your company name',
    yourCustomerWill: 'Your customer will see this name',
    companyLOGO: 'Company LOGO',
    remove: 'Remove',
    uploadImage: 'Upload image',
    address: 'Address',
    email: 'Email',
    modifyEmail: 'Modify Email',
    addEmail: 'Add Email',
    save: 'Save',
    discard: 'Discard',
    originalPassword: 'Original password',
    newPassword: 'New Password',
    repeatNewPassword: 'Repeat new password',
    ERPUserApiToekn: 'ERP User Api Toekn',
    howToUseERPToken: 'How to use ERP user api token',
    weHaveAlso_: 'We have also cooperated or are cooperating with many ERP manufacturers in China to facilitate the users who are using these ERP software to pull orders and mark delivery of confirmed payment orders in ERP system.',
    youOnlyNeed_: 'You only need to copy the ERP user token and paste the token into the corresponding ERP software to start the operation on the confirmed purchase order in ourmall system!',
    picture1: 'Picture 1: copying ERP user token',
    picture2: 'Picture 2:Paste the token to the corresponding ERP software',
    atPresentChina_: 'At present, China ERP software has been connected:',
    MabangERP: 'MabangERP+',
    andSoOn_: 'and so on, other China well-known ERP manufacturers are negotiating and cooperation, and will be launched in succession in the near future.',
    atTheSame: '* At the same time, in order to facilitate the majority of suppliers and sellers to pull orders and ship the purchase orders that have been received, ourmall provides an easy-to-use open API. Suppliers with self-development capabilities can',
    contactWithOur: 'contact with our R & D team',
    dear: 'Dear:',
    ourAppHas_: 'Our app has updated the page you are visiting. We will refresh the page in 10 seconds',
    iSeeRefresh_: 'I see. Refresh the page now.',
    modifyUserEmail: 'Modify User Email',
    verify: 'Verify',
    _captchaYouReceived: 'Please enter the captcha you received',
    captcha: 'Captcha',
    getCaptcha: 'Get captcha',
    secondsLater: 'seconds later',
    _bindYourEmail: 'It\'s time to bind your Email',
    _pleaseBindYourEmail: 'You have started to use the purchase order. In order to receive the new order notice in time, please bind your Email',
    RemindMeLater: 'Remind me later',
    bindNow: 'Bind now',
    _dragToHTheRight: 'Hold down the slider and drag to the right',
    theCaptchaWas_: 'The captcha was sent successfully',
    userEmailModifiedSuccessfully: 'User Email modified successfully',
    validationPassed: 'Validation passed',
    pleaseFillEmail: 'Please fill in the correct Email address',
    _wewillReplyASAP: 'Ticket submitted successfully, we will reply ASAP!',
    theOriginalEntered: 'The original password must be entered',
    theNewPasswordEntered: 'The new password must be entered',
    pleaseEnterAgain: 'Please enter the new password again',
    passwordChangedSuccessfully: 'Password changed successfully',
    companyRequired: 'Name of company is required',
    userInformationSuccessfully: 'User information modified successfully',
    '通知': 'Notice',
    '该站长可处理的单量不足，请联系站长购买单量包。': '',
    '请将该提醒转发您的站长客户：': '',
    '您若有疑问，': 'If you have any questions,',
    '可点此联系客服': 'please contact us',
    '关闭': 'Close',
    "Youdon": "Your customer don't have enough order QTY to create purchase order, please conntact them to recharge order QTY in Recharge Package page. ",
    '财务': '财务',
    '钱包': '钱包',
    '设置收款方式': '设置收款方式'
  },
  dashboard: require('./dashboard/en').lang,
  signin: require('./signin/en').lang,
  signup: require('./signup/en').lang,
  adv: require('./adv/en').lang,
}
