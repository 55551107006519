export const lang = {
  dashboard: 'Dashboard',
  customerAdded: 'Customer added',
  authorizedShopifyCustomers: 'Authorized Shopify customers',
  validInvoice: 'Valid invoice generated',
  pcs: '',
  recentCooperation: 'Recent cooperative customers',
  validInvoice2: 'Valid invoice',
  authorizedShopifyStores: 'Number of authorized Shopify stores',
  importOrder: 'Import another Shopify orders CSV',
  importFirstOrder: 'Import First Shopify orders CSV',
  more: 'more',
  addNumCustomer: 'Add my {num} customer',
  welcomeOurMallApp: 'Welcome to use OurMall Dropshopping App',
	relevantInformation: 'In order to facilitate your communication with your customers, please improve your relevant information',
	customersView: 'This information will be used for your customers to view',
	yourEmail: 'Your Email',
	yourCompany: 'Your company name',
	modify: 'Modify',
	add: 'Add',
	save: 'Save',
	nameEnter: 'Company name must be entered',
  '待完善报价的采购单': 'Waiting for offer price',
  '待发货的采购单': 'Unfulfilled Purchase Order',
  '订单异常禁止发货': 'Abnormal Purchase Order',
  '同步发货信息失败': 'Fulfill Shopify Order Failed',
  '销售统计': 'Sales statistics',
  '每日统计': 'Daily statistics',
  '日期': 'Date',
  '下单客户量': 'Customer Qty',
  '订单数':'Order Qty',
  '销售额US$': 'Sale Amount US$',
  '显示更多': 'Show more',
  '信息汇总': 'Summary',
  '客户': 'Customer',
  '客户店铺': 'Customer Store',
  '托管管理订单': 'Entrusted Order',
  '未合作商品': 'Not cooperation',
  '可以进行报价': ' can offer price',
  '客户已订阅': 'Customer subscribed',
  '日期范围': 'Date Range',
  '今天': 'Today',
  '7天前': 'Last 7 days',
  '30天前': 'Last 30 days',
  '90天前': 'Last 90 days',
  '一年前': 'Last 12 months',
  '自定义': 'Custom',
  '起始': 'Start',
  '截止': 'End',
  '查看更多': ''
}
